import React, { ReactElement } from 'react';
import { Col, Image } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { ButlerTitle } from './styled';

type Props = {
    tile: string;
    picture: string;
};

const Butler = ({ picture, tile }: Props): ReactElement => {
    const { t } = useTranslation('static');
    return (
        <Col xs={12} sm={6} lg={4} xl={3} className="mb-4">
            <ButlerTitle>
                <Trans t={t}>{`static:services.butler.tiles.${tile}`}</Trans>
            </ButlerTitle>
            <Image fluid className="w-100" alt="" src={picture} />
        </Col>
    );
};

export default Butler;
