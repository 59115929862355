import styled from 'styled-components';

const title = styled.p.attrs(() => ({
    className: 'mb-0 pb-2',
}))`
    font-size: 0.9rem;
    min-height: 50px;
`;

const IncludedTitle = styled(title)`
    background-color: ${(props): string => props.theme.ivr.colors.grey.l90};
`;

export { title, IncludedTitle };
