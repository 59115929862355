import styled from 'styled-components';
import { Row } from 'react-bootstrap';

const Spacer = styled.hr.attrs(() => ({
    className: 'mb-1',
    size: '1',
}))`
    background-color: ${(props): string => props.theme.ivr.colors.golden['100']};
    height: 2px;
    opacity: 1;
`;

const OccasionContainer = styled(Row).attrs(() => ({
    className: 'g-0 h-100',
}))`
    background-color: ${(props): string => props.theme.ivr.colors.grey.l90};

    p {
        font-size: 1.1rem;
        font-weight: lighter;
    }
`;

export { OccasionContainer, Spacer };
