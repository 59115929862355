import GreyContainer from 'components/Layout/GreyContainer';
import H1 from 'components/Layout/H1';
import MainPicture from 'components/Layout/MainPicture';
import Butler from 'components/Services/Butler';
import Included from 'components/Services/Included';
import main from 'images/static/services/back_view.png';
import pbt20 from 'images/static/services/butler/babysitting.png';
import pbt16 from 'images/static/services/butler/beaches_and_restaurants_reservations.png';
import pbt8 from 'images/static/services/butler/beauty_salon.png';
import pbt1 from 'images/static/services/butler/boat_and_plane_transfert.png';
import pbt3 from 'images/static/services/butler/butler_service.png';
import pbt18 from 'images/static/services/butler/cooking_class.png';
import pbt13 from 'images/static/services/butler/fitness_class.png';
import pbt22 from 'images/static/services/butler/flight_itinerary.png';
import pbt7 from 'images/static/services/butler/hair_stylist.png';
import pbt5 from 'images/static/services/butler/laundry_service.png';
import pbt9 from 'images/static/services/butler/massage.png';
import pbt11 from 'images/static/services/butler/nautical_sport_and_activities.png';
import pbt19 from 'images/static/services/butler/on_site_chef.png';
import pbt17 from 'images/static/services/butler/party_organisation.png';
import pbt2 from 'images/static/services/butler/rental_reservation.png';
import pbt10 from 'images/static/services/butler/sail_cruises.png';
import pbt15 from 'images/static/services/butler/scuba_diving.png';
import pbt6 from 'images/static/services/butler/spa.png';
import pbt14 from 'images/static/services/butler/sport_coach.png';
import pbt12 from 'images/static/services/butler/tennis.png';
import pbt4 from 'images/static/services/butler/villa_provision.png';
import pbt21 from 'images/static/services/butler/wine_testing.png';
import champagne from 'images/static/services/champagne-stbarth-wedding-ceremony-celebration.png';
import celebration from 'images/static/services/IDEAL-Properties-wedding-celebration-mariage.png';
import pit8 from 'images/static/services/included/bell.png';
import pit6 from 'images/static/services/included/concierge_services.png';
import pit5 from 'images/static/services/included/daily_maid_service.png';
import pit2 from 'images/static/services/included/escort_to_rental_car.png';
import pit3 from 'images/static/services/included/escort_to_the_villa.png';
import pit7 from 'images/static/services/included/luggage_storage.png';
import pit1 from 'images/static/services/included/meet_and_greet.png';
import pit4 from 'images/static/services/included/welcome_gifts.png';
import wedding from 'images/static/services/wedding-beach-marriage-sand-caribbean-ceremony.png';
import React, { ReactElement } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { OccasionContainer, Spacer } from './styled';

const Services = (): ReactElement => {
    const { t, i18n } = useTranslation('static');

    return (
        <React.Fragment>
            <Helmet>
                <meta
                    property="og:url"
                    content={`${process.env.REACT_APP_SITE_URL}services?_locale=${i18n.language}`}
                />
                <meta property="og:site_name" content={t('static:services.meta.og.siteName')} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={t('static:services.meta.og.title')} />
                <meta property="og:description" content={t('static:services.meta.og.description')} />
                <meta
                    property="og:image"
                    content={`${process.env.REACT_APP_SITE_URL}images/static/services/back_view.png`}
                />
                <meta property="twitter:title" content={t('static:services.meta.twitter.title')} />
                <meta property="twitter:description" content={t('static:services.meta.twitter.description')} />
                <meta
                    property="twitter:image"
                    content={`${process.env.REACT_APP_SITE_URL}images/static/services/back_view.png`}
                />
                <meta name="twitter:card" content="summary" />
                <meta property="title" content={t('static:services.meta.title')} />
                <title>{t('static:services.meta.title')}</title>
                <meta property="description" content={t('static:services.meta.description')} />
            </Helmet>
            <Container fluid>
                <div>
                    <MainPicture src={main} alt="beach" />
                </div>
            </Container>
            <Container className="g-2">
                <Row>
                    <Col xs={12} className="text-center">
                        <H1>
                            <Trans t={t}>static:services.title</Trans>
                        </H1>
                    </Col>
                    <Col xs={12} className="pt-3">
                        <p>
                            <Trans t={t}>static:services.paragraphs.1</Trans>
                        </p>
                        <p>
                            <Trans t={t}>static:services.paragraphs.2</Trans>
                        </p>
                        <p>
                            <Trans t={t}>static:services.paragraphs.3</Trans>
                        </p>
                        <p>
                            <Trans t={t}>static:services.paragraphs.4</Trans>
                        </p>
                        <p>
                            <Trans t={t}>static:services.paragraphs.5</Trans>
                        </p>
                    </Col>
                    <Col xs={12} className="mt-5">
                        <Spacer />
                    </Col>
                    <Col xs={12} className="p-3 text-center">
                        <h4 className="fw-light text-uppercase">
                            <Trans t={t}>static:services.included.title</Trans>
                        </h4>
                        <Row className="pt-3">
                            <Included tile="t1" picture={pit1} />
                            <Included tile="t2" picture={pit2} />
                            <Included tile="t3" picture={pit3} />
                            <Included tile="t4" picture={pit4} />
                            <Included tile="t5" picture={pit5} />
                            <Included tile="t6" picture={pit6} />
                            <Included tile="t7" picture={pit7} />
                            <Included tile="t8" picture={pit8} />
                        </Row>
                    </Col>
                </Row>
            </Container>
            <GreyContainer className="flex-grow-1">
                <Container className="g-2 flex-grow-1">
                    <Row>
                        <Col xs={12} className="mt-3">
                            <Spacer />
                        </Col>
                        <Col xs={12} className="text-center pt-3">
                            <h4 className="fw-light text-uppercase">
                                <Trans t={t}>static:services.butler.title</Trans>
                            </h4>
                            <Row className="pt-3">
                                <Butler tile="t1" picture={pbt1} />
                                <Butler tile="t2" picture={pbt2} />
                                <Butler tile="t3" picture={pbt3} />
                                <Butler tile="t4" picture={pbt4} />
                                <Butler tile="t5" picture={pbt5} />
                                <Butler tile="t6" picture={pbt6} />
                                <Butler tile="t7" picture={pbt7} />
                                <Butler tile="t8" picture={pbt8} />
                                <Butler tile="t9" picture={pbt9} />
                                <Butler tile="t10" picture={pbt10} />
                                <Butler tile="t11" picture={pbt11} />
                                <Butler tile="t12" picture={pbt12} />
                                <Butler tile="t13" picture={pbt13} />
                                <Butler tile="t14" picture={pbt14} />
                                <Butler tile="t15" picture={pbt15} />
                                <Butler tile="t16" picture={pbt16} />
                                <Butler tile="t17" picture={pbt17} />
                                <Butler tile="t18" picture={pbt18} />
                                <Butler tile="t19" picture={pbt19} />
                                <Butler tile="t20" picture={pbt20} />
                                <Butler tile="t21" picture={pbt21} />
                                <Butler tile="t22" picture={pbt22} />
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </GreyContainer>
            <Container className="g-2 flex-grow-1 pb-5">
                <Row>
                    <Col xs={12} className="mt-3">
                        <Spacer />
                    </Col>
                    <Col xs={12} className="text-center py-3">
                        <h4 className="fw-light text-uppercase">
                            <Trans t={t}>static:services.occasion.title</Trans>
                        </h4>
                    </Col>
                    <Col xs={12}>
                        <Row className="align-content-stretch g-0">
                            <Col xs={12} xl={6}>
                                <Image src={wedding} alt="" fluid className="mx-auto w-100 d-block" />
                            </Col>
                            <Col xs={12} xl={6}>
                                <OccasionContainer>
                                    <Col xs={12} className="mb-0">
                                        <p className="p-3">
                                            <Trans t={t}>static:services.occasion.content</Trans>
                                        </p>
                                    </Col>
                                    <Col xs={12} xl={9} className="text-center bg-white">
                                        <Image src={champagne} fluid alt="" />
                                    </Col>
                                    <Col xs={12} xl={3} className="d-xl-flex d-none bg-white">
                                        <Image src={celebration} fluid alt="" />
                                    </Col>
                                </OccasionContainer>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default Services;
